import React from "react";
import { FaRegStar, FaRegStarHalf, FaStar } from "react-icons/fa";
import { FaComments, FaUserGroup, FaWandMagicSparkles } from "react-icons/fa6";
import { GiKey } from "react-icons/gi";

export const iconsMap = new Map([
  ["FaRegStarHalf", FaRegStarHalf],
  ["FaStar", FaStar],
  ["FaRegStar", FaRegStar],
  ["FaUserGroup", FaUserGroup],
  ["GiKey", GiKey],
  ["FaWandMagicSparkles", FaWandMagicSparkles],
  ["FaComments", FaComments],
]);

export const renderIcon = (icon) => {
  const Icon = icon;
  return (
    <div className="icon">
      <Icon />
    </div>
  );
};
