import React from "react";
import { Image } from "react-bootstrap";

export const GalleryImage = ({ title, smallImage }) => {
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        <div className="hover-text">
          <p>{title}</p>
        </div>
        <Image src={smallImage} fluid alt={title} />{" "}
      </div>
    </div>
  );
};
