import React from "react";
import { Slide } from "react-awesome-reveal";
import { Col, Container, Image, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";

export const About = (props) => {
  return (
    <section id="about">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <Slide direction={"left"} triggerOnce={true}>
              <Image src="img/about.webp" fluid alt="maxime portrait" />{" "}
            </Slide>
          </Col>
          <Col xs={12} md={6}>
            <div className="about-text">
              <Slide direction={"right"} triggerOnce={true}>
                <h2>{props.data ? props.data.title : "loading..."}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.data ? props.data.paragraph : "loading...",
                  }}
                ></p>
                <h3>{props.data ? props.data.whytitle : "loading..."}</h3>
                <Row>
                  <Col lg={6} sm={6} xs={12}>
                    <ul>
                      {props.data
                        ? props.data.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>
                              {" "}
                              <FaCheck /> {d}
                            </li>
                          ))
                        : "loading"}
                    </ul>
                  </Col>
                  <Col lg={6} sm={6} xs={12}>
                    <ul>
                      {props.data
                        ? props.data.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}>
                              {" "}
                              <FaCheck /> {d}
                            </li>
                          ))
                        : "loading"}
                    </ul>
                  </Col>
                </Row>
              </Slide>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
