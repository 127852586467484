import React from "react";
import { Fade } from "react-awesome-reveal";
import { Col, Container, Row } from "react-bootstrap";
import { iconsMap, renderIcon } from "./shared/icons";

export const Services = (props) => {
  return (
    <section id="services" className="text-center">
      <Container>
        <Row className="justify-content-center section-title">
          <h2>{props.data ? props.data.title : "Loading"}</h2>
        </Row>
        <Row>
          {props.data
            ? props.data.list.map((d, i) => (
                <Col key={`${d.title}-${i}`} xs={6} md={3}>
                  <Fade direction={"top"} delay={i * 500} triggerOnce={true}>
                    {renderIcon(iconsMap.get(d.icon))}
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </Fade>
                </Col>
              ))
            : "Loading..."}
        </Row>
      </Container>
    </section>
  );
};
