import React from "react";
import { Fade } from "react-awesome-reveal";
import { PopupButton } from "react-calendly";
import { Button, Col, Container, Row } from "react-bootstrap";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} className="intro-text">
                <h1>
                  <Fade delay={500} cascade damping={0.15} triggerOnce={true}>
                    {props.data ? props.data.title : "Loading"}
                  </Fade>
                </h1>
                <h1>
                  <Fade delay={1500} cascade damping={0.15} triggerOnce={true}>
                    {props.data ? props.data.title2 : "Loading"}
                  </Fade>
                </h1>
                <Fade delay={2500} triggerOnce={true}>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                </Fade>
                <Fade delay={3000} triggerOnce={true}>
                  <Row className="buttons justify-content-evenly">
                    <Col xs={12} md={4}>
                      <Button href="#about" className="btn btn-custom">
                        {props.data ? props.data.button.about : "Loading"}
                      </Button>
                    </Col>
                    <Col xs={12} md={4}>
                      <PopupButton
                        url="https://calendly.com/maxime-moritz-pro/30min"
                        rootElement={document.getElementById("root")}
                        text="Réserver ! "
                        className="btn btn-custom"
                      />
                    </Col>
                  </Row>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </header>
  );
};
