import React, { useEffect, useState } from "react";
import { Navigation } from "./components/header/navigation";
import { Header } from "./components/header";
import { Services } from "./components/services";
import { About } from "./components/about";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import { Prices } from "./components/prices";
import { Footer } from "./components/footer/footer";
import JsonData from "./data/data.json";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation data={landingPageData.Navigation} />
      <Header data={landingPageData.Header} />
      <main>
        <About data={landingPageData.About} />
        <Services data={landingPageData.Services} />
        <Prices data={landingPageData.Prices} />
        <Gallery data={landingPageData.Gallery} />
        {/*<Testimonials data={landingPageData.Testimonials} />*/}
        <Contact data={landingPageData.Contact} />
      </main>

      <Footer />
      <ToastContainer position="bottom-center" theme="colored" />
    </div>
  );
};

export default App;
