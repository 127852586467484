import React from "react";

export const Footer = (props) => {
  return (
    <div id="footer">
      <div className="container text-center">
        <p>
          &copy; 2024 Design by &nbsp;
          <a href="http://www.tmottart.com" rel="nofollow">
            Tristan MOTTART
          </a>
        </p>
      </div>
    </div>
  );
};
