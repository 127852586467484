import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import { AttentionSeeker } from "react-awesome-reveal";
import { Loader } from "./shared/loader";
import { toast } from "react-toastify";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  FaClock,
  FaEnvelope,
  FaInstagram,
  FaMapLocationDot,
  FaPhone,
} from "react-icons/fa6";

const initialState = {
  isLoading: false,
};
const initialFormData = Object.freeze({
  name: "",
  email: "",
  message: "",
});

export const Contact = (props) => {
  const [formData, updateFormData] = useState(initialFormData);
  const [{ isLoading }, setState] = useState(initialState);
  const formRef = useRef();

  const enableLoader = () =>
    setState((prevState) => ({ ...prevState, isLoading: true }));
  const disabledLoader = () =>
    setState((prevState) => ({ ...prevState, isLoading: false }));

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value.trim(),
    });
  };

  const resetForm = (e) => {
    formRef.current.reset();
    updateFormData({
      initialFormData,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    enableLoader();
    emailjs
      .send(
        "service_4ya54th",
        "template_kawyum6",
        formData,
        "NPlKEQfupU1BaoyTa",
      )
      .then(
        (result) => {
          resetForm();
          toast.success("Le message a bien été envoyé !");
        },
        (error) => {
          console.log(error);
          toast.error(
            "Une erreur est survenue, veuillez contacter par mail ou par téléphone.",
          );
        },
      )
      .finally(() => {
        disabledLoader();
      });
  };
  return (
    <section id="contact">
      {isLoading ? <Loader /> : null}
      <Container>
        <Row>
          <Col md={8}>
            <Row>
              <div className="section-title">
                <h2>
                  {props.data ? props.data.title : "loading"}{" "}
                  <AttentionSeeker effect={"shakeX"}>👋</AttentionSeeker>{" "}
                </h2>
                <p>{props.data ? props.data.description : "loading"}</p>
              </div>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} className="mb-4">
                    <Form.Control
                      required
                      id="name"
                      type="text"
                      placeholder="Nom"
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Veuillez renseigner un nom
                    </Form.Control.Feedback>
                  </Col>
                  <Col md={6} className="mb-4">
                    <Form.Control
                      required
                      id="email"
                      type="email"
                      placeholder="Email"
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Veuillez renseigner un email
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Control
                      required
                      id="message"
                      as="textarea"
                      rows={4}
                      onChange={handleChange}
                      placeholder="Veuillez laisser votre message..."
                    />
                    <Form.Control.Feedback type="invalid">
                      Veuillez renseigner un message
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <div id="success"></div>
                <Button type="submit" className="btn-custom btn-lg">
                  Envoyer
                </Button>
              </Form>
            </Row>
          </Col>
          <Col md={{ span: 3, offset: 1 }} className="contact-info">
            <div className="contact-item">
              <h3>Information</h3>
              <p>
                <span>
                  <FaMapLocationDot /> Adresse
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <FaClock /> Ouverture
                </span>{" "}
                {props.data ? props.data.open : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <FaPhone /> Téléphone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <FaEnvelope /> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </Col>
          <Col md={12}>
            <Row>
              <div className="social">
                <ul>
                  <li>
                    <a
                      href={props.data ? props.data.instagram : "/"}
                      id="instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                </ul>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
