import { GalleryImage } from "./image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export const Gallery = (props) => {
  return (
    <section id="portfolio" className="text-center">
      <Container>
        <div className="section-title">
          <h2>{props.data ? props.data.title : "Loading"}</h2>
          <p>{props.data ? props.data.description : "Loading"}</p>
        </div>
        <Row>
          {props.data
            ? props.data.list.map((d, i) => (
                <Col key={`${d.title}-${i}`} sm={6} md={4} lg={4}>
                  <GalleryImage title={d.title} smallImage={d.smallImage} />
                </Col>
              ))
            : "Loading..."}
        </Row>
      </Container>
    </section>
  );
};
